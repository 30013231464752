import { PageSkeleton } from "#components/pages";

export function ContactPage() {
  const title = "Contact Us";
  const heading = "Contact Us";

  return (
    <PageSkeleton name="" title={title} heading={heading}>
	<p>
		Contact email: <a href="mailto:contact@kemono.ru">contact@kemono.ru</a>
	</p>
	<p>
		Please allow up to 3-5 working days for your request to be processed. You may not receive a response if we are not interested.
	</p>
    </PageSkeleton>
  );
}